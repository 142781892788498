<template>
    <b-card title="Add Compressor Warranty">
        <!-- <b-col cols="12">
            <label>Name</label>
            <b-form-input placeholder="Name" v-model="form.name"></b-form-input>
        </b-col> -->

        <b-col cols="12" style="margin-bottom : 15px;">
            <label>
                Category (Product hierarchy level 1) <span style="color:red">(*)</span>
            </label>

            <b-form-input
                v-model="form.category">
            </b-form-input>
        </b-col>

        <b-col cols="12" style="margin-bottom : 15px;">
            <label>Type <span style="color:red">(*)</span></label>
            <b-form-input placeholder="Type" v-model="form.type"></b-form-input>
        </b-col>

        <b-col cols="12" style="margin-bottom : 15px;">
            <label>Month <span style="color:red">(*)</span></label>
            <b-form-input placeholder="Extend Month" v-model="form.month"></b-form-input>
        </b-col>

        <b-col cols="12" class="mt-2">
            <b-button variant="success" @click="doCreateCompressorWarranty">Save</b-button>
        </b-col>
    </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sleep }from '@/utils/utils.js'
export default {
    data() {
        return {
            form: {

            },
        }
    },
    mounted() {
        // this.getCategories();
        
    },
    methods: {
        ...mapActions({
            createCompressorWarranty: 'warranty/createCompressorWarranty',
            getCategories: 'category/getCategories',
        }),
        doCreateCompressorWarranty() {
            this.createCompressorWarranty(this.form).then(async () => {
                this.$bvToast.toast(`Create Compressor Warranty Successful`, {
                title: "Success",
                variant: "success",
                solid: true,
                });    
                await sleep(1000);
                this.$router.go(-1);
            })
            .catch((err) => {
                console.log({ err });
                this.$bvToast.toast(
                    `Create Compressor Failed`,
                    {
                    variant: "danger",
                    solid: true,
                    title: err.response.data.errors[0].message,
                    }
                );
                });
        }
    },
    computed: {
        ...mapState({
            'categories': (state) => {
                return state.category.categories.map((c) => ({ value: c.id, text: c.name }))
            }
        })
    }
}
</script>